// signout.page.tsx

import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Result, Button } from 'antd';
import { Navigate } from 'react-router-dom';

import { useAuth } from '@contexts/auth.context';
import { AuthService } from '@services/auth.service';
import MainLayout from '@layouts/main.layout';

import './signout.page.css';

type StatusType = 'loading' | 'ok' | 'ko';

type Props = {};

const SignoutPage: React.FC<Props> = () => {
	const { setAuth } = useAuth();
	const [status, setStatus] = useState<StatusType>('loading');
	const disconnected = useCallback(() => {
		setStatus('loading');
		AuthService.signout()
			.then((_) => {
				setTimeout(() => {
					// this.setState({ status: StatusType.Ok });
					setAuth('disconnected');
				}, 1000);
			})
			.catch((error: any) => {
				setStatus('ko');
			});
	}, [setStatus, setAuth]);

	useEffect(() => {
		disconnected();
	}, [disconnected]);

	return (
		<MainLayout>
			{status === 'loading' && <Spin className="spin" size="large" />}
			{status === 'ok' && <Navigate to="/" />}
			{status === 'ko' && (
				<Result
					status="warning"
					title="Connection error, please try again."
					extra={
						<Button
							type="primary"
							style={{ width: '100%' }}
							size={'large'}
							onClick={() => {
								disconnected();
							}}
						>
							Try again
						</Button>
					}
				/>
			)}
		</MainLayout>
	);
};

export default SignoutPage;
