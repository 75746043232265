// layout.tsx

import * as React from 'react';
import { Typography } from 'antd';

import Logo from '@assets/pxlapps_logo.png';

import './main.layout.css';

const { Title } = Typography;

type Props = {};

const MainLayout: React.FC<Props> = ({ children }) => {
	return (
		<div className="auth-layout">
			<div className="outer">
				<div className="inner">
					<div className="inner-header">
						<img src={Logo} alt="PXL APPS" />
					</div>
					<div className="content">
						<Title level={2} style={{ textAlign: 'center' }}>
							Sign in
						</Title>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MainLayout;
