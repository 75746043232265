// settings.service.ts

import Superagent from 'superagent';

export class SettingsService {
	public static get(): Promise<Superagent.Response> {
		return new Promise((resolve, reject) => {
			Superagent.get('/api/settings')
				.set('Accept', 'application/json')
				.withCredentials()
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
}
