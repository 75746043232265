// auth.context.tsx

import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import * as _ from 'lodash';

import { AuthService } from '@services/auth.service';

type StatusType = 'undetermined' | 'disconnected' | 'connected';

export type AuthContextType = {
	status: StatusType;
	checkAuth: () => void;
	setAuth: (status: StatusType) => void;
};

export type AuthContextProviderType = AuthContextType & {};

export const AuthContext = React.createContext<
	AuthContextProviderType | undefined | null
>(undefined);

type UseAuthType = {
	status: StatusType;
	checkAuth: () => void;
	setAuth: (status: StatusType) => void;
};

export const useAuth = (): UseAuthType => {
	const authContext = useContext(AuthContext);
	if (_.isNil(authContext)) {
		throw new Error('Please configure auth context correctly!');
	}
	return authContext;
};

export type AuthProps = {
	authContext: UseAuthType;
};

export function withAuth<T extends AuthProps & { forwardedRef?: any }>(
	WrappedComponent: React.ComponentType<T>
) {
	const displayName =
		WrappedComponent.displayName || WrappedComponent.name || 'Component';

	function ComponentWithTheme({
		forwardedRef,
		...rest
	}: Omit<T, keyof AuthProps>) {
		const authProps = useAuth();

		const passDownProps = {
			...rest,
			authContext: authProps,
		};
		(passDownProps as any).ref = forwardedRef;
		/* if (options.withRef && forwardedRef) {
      passDownProps.ref = forwardedRef;
    } else if (!options.withRef && forwardedRef) {
      passDownProps.forwardedRef = forwardedRef;
    } */
		return React.createElement(WrappedComponent, passDownProps as any);
	}

	ComponentWithTheme.displayName = `withAuth(${displayName})`;
	ComponentWithTheme.WrappedComponent = WrappedComponent;
	const forwardRef = (props: T, ref: React.Ref<ReactNode>) =>
		React.createElement(
			ComponentWithTheme,
			Object.assign({}, props, { forwardedRef: ref })
		);

	return React.forwardRef(forwardRef);
}

type Props = {};

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
	const [status, setStatus] = useState<StatusType>('undetermined');
	const [providerContext, setProviderContext] = useState<AuthContextType>();

	const checkAuth = useCallback(() => {
		AuthService.checkAuth()
			.then((_) => {
				setStatus('connected');
			})
			.catch((error) => {
				setStatus('disconnected');
			});
	}, []);

	useEffect(() => {
		setProviderContext((state) => ({
			status,
			checkAuth,
			setAuth: (value: StatusType) => {
				setStatus(value);
			},
		}));
	}, [status, checkAuth]);

	useEffect(() => {
		checkAuth();
	}, [checkAuth]);

	return (
		<AuthContext.Provider value={providerContext}>
			{providerContext && children}
		</AuthContext.Provider>
	);
};
