// App.tsx

import React from 'react';
// import '@fontawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/free-solid-svg-icons';

import { AuthContextProvider } from '@app/contexts/auth.context';
import { SettingsContextProvider } from '@contexts/settings.context';
import MainPage from '@app/main.page';

import './App.css';

const App: React.FC = () => {
	return (
		<SettingsContextProvider>
			<AuthContextProvider>
				<MainPage />
			</AuthContextProvider>
		</SettingsContextProvider>
	);
};

export default App;
