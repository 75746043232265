// signin.page.tsx

import React, { useState } from 'react';
import { Alert } from 'antd';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Superagent from 'superagent';

import { useAuth } from '@contexts/auth.context';
import { AuthService } from '@services/auth.service';
import MainLayout from '@layouts/main.layout';
import { SigninStep1Section } from '@sections/signin-step1.section';
import { SigninStep2Section } from '@sections/signin-step2.section';
import { Nullable } from '@utils/misc.utils';
import { logger } from '@utils/logger.util';

import './signin.page.css';

type Props = {};

const SigninPage: React.FC<Props> = () => {
	const navigate = useNavigate();
	const { setAuth } = useAuth();
	const [searchParams] = useSearchParams();
	const [error, setError] = useState(undefined);
	const [state, setState] = useState<Nullable<string>>(undefined);
	return (
		<MainLayout>
			{error && (
				<Alert
					style={{ margin: '20px 0' }}
					message={error}
					type="error"
					showIcon
				/>
			)}
			{state === undefined && (
				<SigninStep1Section
					onSubmit={({ username, password }) => {
						setError(undefined);
						AuthService.signinStep1({
							username,
							password,
						})
							.then((response: Superagent.Response) => {
								const token = JSON.parse(response?.text).data.token;
								setState(token);
							})
							.catch((error: any) => {
								logger()?.log(JSON.stringify(error.response.text));
								const res = error.response?.text
									? JSON.parse(error.response?.text)
									: { message: 'Internal error' };
								setError(res.message);
							});
					}}
				/>
			)}
			{state && (
				<SigninStep2Section
					onSubmit={(token) => {
						setError(undefined);
						AuthService.signinStep2({ state: state, token: token })
							.then((response: Superagent.Response) => {
								setAuth('connected');
								if (searchParams.get('rd')) {
									navigate(`/?rd=${searchParams.get('rd')!!}`);
								} else {
									navigate('/');
								}
							})
							.catch((error: any) => {
								logger()?.log(JSON.stringify(error.response.text));
								const res = error.response?.text
									? JSON.parse(error.response?.text)
									: { message: 'Internal error' };
								setError(res.message);
							});
					}}
				/>
			)}
		</MainLayout>
	);
};

export default SigninPage;
