// redirect.page.tsx

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSettings } from '@contexts/settings.context';
import { RedirectSchema } from '@validators/redirect.validator';

type Props = {};

const RedirectPage: React.FC<Props> = () => {
	const { settings } = useSettings();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const redirect = RedirectSchema.isValidSync(searchParams.get('rd'))
			? searchParams.get('rd')!!
			: settings?.redirect.default!!;
		window.location.href = redirect;
	}, [settings?.redirect, searchParams]);
	return null;
};

export default RedirectPage;
