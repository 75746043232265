// auth.service.ts

import Superagent from 'superagent';

export type SigninStep1Params = {
	username: string;
	password: string;
};

export type SigninStep2Params = {
	state: string;
	token: string;
};

export class AuthService {
	public static signinStep1(
		value: SigninStep1Params
	): Promise<Superagent.Response> {
		return new Promise((resolve, reject) => {
			Superagent.post('/api/signin1')
				.send(value)
				.set('Accept', 'application/json')
				.withCredentials()
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	public static signinStep2(
		value: SigninStep2Params
	): Promise<Superagent.Response> {
		return new Promise((resolve, reject) => {
			Superagent.post('/api/signin2')
				.send(value)
				.set('Accept', 'application/json')
				.withCredentials()
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	public static signout(): Promise<Superagent.Response> {
		return new Promise((resolve, reject) => {
			Superagent.get('/api/signout')
				.set('Accept', 'application/json')
				.withCredentials()
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	public static checkAuth(): Promise<Superagent.Response> {
		return new Promise((resolve, reject) => {
			Superagent.get('/api/checkauth')
				.set('Accept', 'application/json')
				.withCredentials()
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
}
