// create-simple.form.tsx

import React, { ReactNode } from 'react';
import * as _ from 'lodash';
import { FormItem } from 'formik-antd';

export const createSimpleField = (
	name: string,
	label: string,
	values: any,
	errors: any,
	touched: any,
	input: ReactNode,
	disabled: boolean = false
) => {
	if (disabled) {
		_.set(values, name, undefined);
	}
	return (
		<FormItem
			style={{
				display: disabled ? 'none' : '',
				margin: '0px',
				width: '100%',
				padding: '0px',
			}}
			name={label}
			label={label}
			validateStatus={
				_.get(errors, name) && _.get(touched, name) ? 'error' : ''
			}
			help={
				_.get(errors, name) && _.get(touched, name) ? _.get(errors, name) : ''
			}
		>
			{input}
		</FormItem>
	);
};

export const createSimpleFieldWithoutLabel = (
	name: string,
	values: any,
	errors: any,
	touched: any,
	input: ReactNode,
	disabled: boolean = false
) => {
	if (disabled) {
		_.set(values, name, undefined);
	}
	return (
		<FormItem
			style={{
				display: disabled ? 'none' : 'inline-block',
				margin: '0px',
				width: '100%',
			}}
			name={name}
			validateStatus={
				_.get(errors, name) && _.get(touched, name) ? 'error' : ''
			}
			help={
				_.get(errors, name) && _.get(touched, name) ? _.get(errors, name) : ''
			}
		>
			{input}
		</FormItem>
	);
};
