// logger.util.tsx

import React from 'react';

export const loggerSetting = {
	debug: false,
};

export const logger = () => {
	return loggerSetting.debug ? console : null;
};

/* eslint-disable */
export const DisplayFormikState = (props: any) => (
	<div style={{ margin: '1rem 0' }}>
		<h3 style={{ fontFamily: 'monospace' }} />
		<pre
			style={{
				background: '#f6f8fa',
				fontSize: '.65rem',
				padding: '.5rem',
			}}
		>
			<strong>props</strong> = {JSON.stringify(props, null, 2)}
		</pre>
	</div>
);
/* eslint-enable */
