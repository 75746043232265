// signin-step1.page.tsx

import React from 'react';
import { Input, SubmitButton } from 'formik-antd';
import { Formik, Form } from 'formik';

import { createSimpleField } from '@components/create-simple.form';
import { SigninStep1Schema } from '@validators/auth.validator';

import './signin-step1.section.css';

type SubmitValue = {
	username: string;
	password: string;
};

type Props = {
	onSubmit: (value: SubmitValue) => void;
};

export const SigninStep1Section: React.FC<Props> = ({ onSubmit }) => {
	return (
		<Formik
			onSubmit={() => {}}
			initialValues={{ username: '', password: '' }}
			validationSchema={SigninStep1Schema}
			enableReinitialize={true}
		>
			{({ errors, touched, values, handleReset }) => {
				return (
					<Form className="signin-step1-container">
						<div>
							{createSimpleField(
								'username',
								'',
								values,
								errors,
								touched,
								<Input
									className="signin-step1-username"
									size="large"
									name="username"
									prefix={
										<i className="fas fa-user" style={{ color: '#939393' }}></i>
									}
									placeholder="Enter a username"
								/>
							)}
						</div>
						<div>
							{createSimpleField(
								'password',
								'',
								values,
								errors,
								touched,
								<Input.Password
									className="signin-step1-password"
									size="large"
									name="password"
									prefix={
										<i className="fas fa-key" style={{ color: '#939393' }}></i>
									}
									placeholder="Enter a password"
								/>
							)}
						</div>
						<SubmitButton
							className="signin-step1-button"
							size={'large'}
							disabled={!SigninStep1Schema.isValidSync(values)}
							onClick={() => {
								onSubmit({
									username: values.username,
									password: values.password,
								});
								values.password = '';
							}}
						>
							Sign in
						</SubmitButton>
					</Form>
				);
			}}
		</Formik>
	);
};
