// settings.context.tsx

import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import * as _ from 'lodash';

import { SettingsService } from '@services/settings.service';
import { loggerSetting } from '@app/utils/logger.util';

type StatusType = 'waiting' | 'loaded' | 'error';

type SettingsType = {
	debug: boolean;
	redirect: {
		default: string;
	};
};

export type SettingsContextType = {
	status: StatusType;
	settings?: SettingsType;
};

export type SettingsContextProviderType = SettingsContextType & {};

export const SettingsContext = React.createContext<
	SettingsContextProviderType | undefined | null
>(undefined);

type UseSettingsType = {
	status: StatusType;
	settings?: SettingsType;
};

export const useSettings = (): UseSettingsType => {
	const settingsContext = useContext(SettingsContext);
	if (_.isNil(settingsContext)) {
		throw new Error('Please configure settings context correctly!');
	}
	return settingsContext;
};

export type SettingsProps = {
	settingsContext: UseSettingsType;
};

export function withSettings<T extends SettingsProps & { forwardedRef?: any }>(
	WrappedComponent: React.ComponentType<T>
) {
	const displayName =
		WrappedComponent.displayName || WrappedComponent.name || 'Component';

	function ComponentWithTheme({
		forwardedRef,
		...rest
	}: Omit<T, keyof SettingsProps>) {
		const settingsProps = useSettings();

		const passDownProps = {
			...rest,
			settingsContext: settingsProps,
		};
		(passDownProps as any).ref = forwardedRef;
		/* if (options.withRef && forwardedRef) {
      passDownProps.ref = forwardedRef;
    } else if (!options.withRef && forwardedRef) {
      passDownProps.forwardedRef = forwardedRef;
    } */
		return React.createElement(WrappedComponent, passDownProps as any);
	}

	ComponentWithTheme.displayName = `withSettings(${displayName})`;
	ComponentWithTheme.WrappedComponent = WrappedComponent;
	const forwardRef = (props: T, ref: React.Ref<ReactNode>) =>
		React.createElement(
			ComponentWithTheme,
			Object.assign({}, props, { forwardedRef: ref })
		);

	return React.forwardRef(forwardRef);
}

type Props = {};

export const SettingsContextProvider: React.FC<Props> = ({ children }) => {
	const [status, setStatus] = useState<StatusType>('waiting');
	const [settings, setSettings] = useState<SettingsType>();
	const [providerContext, setProviderContext] = useState<SettingsContextType>();

	const checkAuth = useCallback(() => {
		SettingsService.get()
			.then((value) => {
				setSettings(value.body);
				setStatus('loaded');
			})
			.catch((error) => {
				setStatus('error');
			});
	}, []);

	useEffect(() => {
		setProviderContext({
			status,
			settings,
		});
		loggerSetting.debug = settings?.debug!!;
	}, [status, settings]);

	useEffect(() => {
		checkAuth();
	}, [checkAuth]);

	return (
		<SettingsContext.Provider value={providerContext}>
			{status === 'loaded' && children}
		</SettingsContext.Provider>
	);
};
