// main.page.tsx

import React from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';

import SigninPage from '@pages/signin.page';
import SignoutPage from '@pages/signout.page';
import RedirectPage from '@pages/redirect.page';

import { useAuth } from '@contexts/auth.context';
import { logger } from '@utils/logger.util';

type Props = {};

const MainPage: React.FC<Props> = () => {
	const { status } = useAuth();
	logger()?.log(`isAuth = ${status}`);
	const app = {
		undetermined: '',
		disconnected: (
			<Routes>
				<Route path="/signin" element={<SigninPage />} />
				<Route path="*" element={<Navigate to="/signin" />} />
			</Routes>
		),
		connected: (
			<Routes>
				<Route path="/signout" element={<SignoutPage />} />
				<Route path="*" element={<RedirectPage />} />
			</Routes>
		),
	};
	return <Router>{app[status]}</Router>;
};

export default MainPage;
