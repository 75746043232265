// signin-step2.page.tsx

import React, { useRef } from 'react';
import { SubmitButton } from 'formik-antd';
import { Formik, Form } from 'formik';
import OtpInput from 'react-otp-input';

import { createSimpleField } from '@components/create-simple.form';
import { SigninStep2Schema } from '@validators/auth.validator';

import './signin-step2.section.css';

type Props = {
	onSubmit: (token: string) => void;
};

export const SigninStep2Section: React.FC<Props> = ({ onSubmit }) => {
	const optInputRef = useRef<OtpInput>(null);
	return (
		<Formik
			onSubmit={() => {}}
			initialValues={{ token: '' }}
			validationSchema={SigninStep2Schema}
			enableReinitialize={true}
		>
			{({ errors, touched, values, handleReset, setValues, setTouched }) => {
				return (
					<Form className="signin-step2-container">
						<div>
							{createSimpleField(
								'token',
								'',
								values,
								errors,
								touched,
								<div className="signin-step2-otp-container">
									<OtpInput
										ref={optInputRef}
										shouldAutoFocus
										inputStyle="signin-step2-otp-input"
										value={values.token}
										onChange={(v: any) => {
											setValues({ ...values, token: v });
										}}
										isInputNum
										numInputs={6}
										separator={<span>-</span>}
									/>
								</div>
							)}
						</div>
						<SubmitButton
							style={{ width: '100%' }}
							size={'large'}
							disabled={!SigninStep2Schema.isValidSync(values)}
							onClick={() => {
								const token = values.token;
								onSubmit(token);
								setTouched({ token: false });
								setValues({ ...values, token: '' });
								optInputRef.current?.focusInput(0);
							}}
						>
							Sign in
						</SubmitButton>
					</Form>
				);
			}}
		</Formik>
	);
};
